import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { profileInit } from '../../actions/profile';

const styles = () => ({
    profileContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    profileContainerRow: {
        width: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    profileContainerTextField: {
        width: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        border: 'none',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
        background: 'none',
        color: 'rgba(202, 202, 202, 1.00)',
        paddingBottom: '4px'
    },
});

const Profile = (props) => {
    const { classes, inited, init, profileData, isMobileDevice } = props;

    const stylesForMobile = (className) => {
        if (isMobileDevice && className === 'profileContainerRow')
            return { flexDirection: 'column', alignItems: 'start' }
        if (isMobileDevice && className === 'profileContainerTextField')
            return { marginTop: '3px' }
    };

    React.useEffect(() => {
        init();
    }, [init]);

    return (
        <>
            {!inited && <LinearProgress variant="query" />}
            {
                inited &&
                profileData.length !== 0 &&
                (
                    <div className={classes.profileContainer}>
                        <div
                            className={classes.profileContainerRow}
                            style={stylesForMobile('profileContainerRow')}
                        >
                            <span>Полное имя</span>
                            <input
                                value={profileData.fullName ? profileData.fullName : '---'}
                                className={classes.profileContainerTextField}
                                style={stylesForMobile('profileContainerTextField')}
                                title={profileData.fullName ? profileData.fullName : '---'}
                                disabled={true}
                            />
                        </div>
                        <div
                            className={classes.profileContainerRow}
                            style={stylesForMobile('profileContainerRow')}
                        >
                            <span>Email</span>
                            <input
                                value={profileData.email ? profileData.email : '---'}
                                className={classes.profileContainerTextField}
                                style={stylesForMobile('profileContainerTextField')}
                                title={profileData.email ? profileData.email : '---'}
                                disabled={true}
                            />
                        </div>
                        <div
                            className={classes.profileContainerRow}
                            style={stylesForMobile('profileContainerRow')}
                        >
                            <span>Телефон</span>
                            <input
                                value={profileData.phone ? profileData.phone : '---'}
                                className={classes.profileContainerTextField}
                                style={stylesForMobile('profileContainerTextField')}
                                title={profileData.phone ? profileData.phone : '---'}
                                disabled={true}
                            />
                        </div>
                        <div
                            className={classes.profileContainerRow}
                            style={stylesForMobile('profileContainerRow')}
                        >
                            <span>Должность</span>
                            <input
                                value={profileData.roles[0] ? profileData.roles[0] : '---'}
                                className={classes.profileContainerTextField}
                                style={stylesForMobile('profileContainerTextField')}
                                title={profileData.roles[0] ? profileData.roles[0] : '---'}
                                disabled={true}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        inited: state.profile.inited,
        profileData: state.profile.profileData,
        isMobileDevice: state.page.isMobileDevice
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(profileInit())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));