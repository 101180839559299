import ElkUser from "../api/ElkUser";
import {isMobileDevice} from '@ksbteam/core/api/MobileDevice/index';

const initialState = {
    drawerLeft: {open: false},
    isMobileDevice: isMobileDevice(),
    elkUser: new ElkUser(),
    firebaseInited: false
};

export function page(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'PAGE_TOGGLE_DRAWER_LEFT':
            return Object.assign({}, state, {
                drawerLeft: {
                    open: !state.drawerLeft.open
                }
            });
        case 'PAGE_FIREBASE_SET_INITED':
            return Object.assign({}, state, {
                firebaseInited: action.inited
            });
        default:
            return state;
    }
}
