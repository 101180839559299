import React, {useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {mainMenu} from '../../api/MainMenu';

const styles = theme => ({
    list: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        [`@media (max-width: 1150px)`]: {
            display: 'none'
        }
    },
    listItem: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing()
    },
    navLink: {
        display: 'block',
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        // borderRadius: '0 50px 50px 0',
        '&.active': {
            // background: '#e7e7e7',
            color: theme.palette.secondary.light,
        },
        '&.active *': {
            color: theme.palette.secondary.light,
        }
    }
});

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delayedOnce: false,
            show: false
        };
    }

    componentDidMount() {
        if (this.state.delayedOnce === false) {
            setTimeout(() => {
                this.setState({show: true});
                this.setState({delayedOnce: true});
            }, 500);
        }
    }

    render() {
        const {classes} = this.props;
        const menuItems = mainMenu.getHeaderItems();
        return (
            <List className={classes.list}>
                {menuItems.map(menuItem =>
                    <NavLink
                        key={menuItem.url}
                        to={menuItem.url}
                        className={classes.navLink}
                    >
                        <ListItem className={classes.listItem}>
                            <ListItemText primary={
                                <Typography variant="body1">{menuItem.title}</Typography>
                            }/>
                        </ListItem>
                    </NavLink>
                )}
            </List>
        );
    }
}

export default withStyles(styles)(NavBar);
