import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@ksbteam/core/components/Form/Field/TextField';
import SnackbarError from '@ksbteam/core/components/Snackbar/SnackbarError';
import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {t, language} from '@ksbteam/core/api/DummyTranslate';

const messageHideTimeout = 2000;

const styles = theme => ({
    snackbarWrapper: {
        marginBottom: theme.spacing(2)
    },
    snackbarSuccess: {
        color: '#ffffff',
        backgroundColor: '#43a047',
        width: '100%',
        maxWidth: '100%',
        transition: '0.5s',
        webkitTransition: '0.5s',
        mozTransition: '0.5s',
        oTransition: '0.5s'
    },
    mainText: {
        marginBottom: theme.spacing()
    },
    fieldError: {
        color: 'red',
        marginTop: theme.spacing(),
    },
    sendCodeButton: {
        textTransform: 'none',
        minHeight: theme.spacing(2),
        marginTop: theme.spacing(),
        color: theme.palette.text.secondary,
        fontWeight: 'normal',
        '&:hover, &:active': {
            backgroundColor: 'transparent'
        },
        padding: 0
    }
});

class SendCodeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkCodeError: '',
            codeSendMessage: '',
            isLoading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
    }

    async handleSubmit(values, actions) {
        actions.setSubmitting(true);
        try {
            this.setState({loginError: ''});
            await this.props.onSubmit(values.email);
        } catch (error) {
            this.setState({
                selectEmailError: t('An error occurred while checking the code. Please contact site administrator or try again later')
            })
        }
        actions.setSubmitting(false);
    }

    handleHideMessageByTimer() {
        let that = this;
        setTimeout(() => that.setState({codeSendMessage: ''}), messageHideTimeout);
    }

    async handleSendCode() {
        this.setState({isLoading: true});
        await this.props.onSendCode();
        this.setState({isLoading: false, codeSendMessage: t('Code has been resent')}, this.handleHideMessageByTimer);
    }

    render() {
        const {classes} = this.props;
        if (!!this.state.selectEmailError) {
            return <div className={classes.snackbarWrapper}>
                <SnackbarError message={this.state.selectEmailError}/>
            </div>
        }
        const msgStyles = !!this.state.codeSendMessage.length
            ? {
                height: 'auto',
                visibility: 'visible',
                opacity: 1,
                margin: '0 0 1em 0'
            } : {
                height: 0,
                visibility: 'hidden',
                opacity: 0,
                padding: 0,
                margin: 0
            };
        return (
            <React.Fragment>
                <SnackbarContent
                    style={msgStyles}
                    className={classes.snackbarSuccess}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={'j239'}>{this.state.codeSendMessage}</span>
                    }
                />
                <Formik
                    initialValues={{code: ''}}
                    onSubmit={(values, actions) => {
                        this.props.onSubmit(values, actions);
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        return this.props.onValidate(values['code']).then((response) => {
                            return new Promise((resolve, reject) => {
                                let errors = {};
                                if (!response.ok) {
                                    errors.code = t('Invalid confirmation code');
                                }
                                if (Object.keys(errors).length) {
                                    reject(errors);
                                } else {
                                    resolve();
                                }
                            });
                        });
                    }}
                    validationSchema={Yup.object().shape({
                        code: Yup.string()
                            .required(t('Field is required')),
                    })}
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form className={'simple-form'} onSubmit={handleSubmit} autoComplete={'off'}>
                                <Typography variant="body1" className={classes.mainText}>
                                    {
                                        language === 'ru'
                                            ? `На почту ${this.props.email} был отправлен одноразовый код подтверждения`
                                            : `A one-time verification code has been sent to the mail ${this.props.email}`
                                    }
                                </Typography>
                                <div className="simple-form-row" style={{marginBottom: '1em'}}>
                                    <Field
                                        name={'code'}
                                        id={'code'}
                                        label={''}
                                        type={'text'}
                                        placeholder={t('Enter the code')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        component={TextField}
                                    />
                                    {errors.code && touched.code &&
                                    <Typography variant="caption" gutterBottom className={classes.fieldError}>
                                        {errors.code}
                                    </Typography>
                                    }
                                </div>

                                <Button
                                    disabled={isSubmitting || this.isLoading}
                                    type={'submit'}
                                    variant="contained"
                                    size="medium"
                                    color="primary" style={{width: '100%', marginTop: '2em'}}
                                >
                                    {t('Continue')}
                                </Button>
                                <Button onClick={this.handleSendCode} size={'small'} className={classes.sendCodeButton}>
                                    {t('Resend code')}
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(SendCodeForm);

