import {initFirebase} from "@ksbteam/core/api/Firebase";

export function pageToggleDrawerLeft() {
    return {type: 'PAGE_TOGGLE_DRAWER_LEFT'};
}

export function pageInitFirebase() {
    return async (dispatch, getState) => {
        const state = getState(), {elkUser, firebaseInited} = state.page;
        if (!firebaseInited && elkUser.loggedIn()) {
            initFirebase(elkUser.restClient);
            dispatch(pageFirebaseSetInited(true));
        }
    };
}

export function pageFirebaseSetInited(inited) {
    return {
        type: 'PAGE_FIREBASE_SET_INITED',
        inited
    };
}
