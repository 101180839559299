export function reportsSetInited(inited) {
    return {
        type: 'REPORTS_SET_INITED',
        inited
    };
}

export function reportsSetCurrentReport(currentReport) {
    return {
        type: 'REPORTS_SET_CURRENT_REPORT',
        currentReport
    };
}

export function reportsUpdateCurrentReport(e) {
    return async (dispatch, getState) => {
        dispatch(reportsSetCurrentReport(e.target.value));
    };
}

export function reportsSetReports(reports) {
    return {
        type: 'REPORTS_SET_REPORTS',
        reports
    };
}

export function reportsInit() {
    return async (dispatch, getState) => {
        const state = getState(), client = state.page.elkUser.restClient;
        dispatch(reportsSetInited(false));
        let reports = await client.get('service_desc/report/list');
        dispatch(reportsSetReports(reports));
        dispatch(reportsSetInited(true));
    };
}

export function reportsSetDataGridRef(dataGridRef) {
    return {
        type: 'REPORTS_SET_DATAGRID_REF',
        dataGridRef
    };
}
