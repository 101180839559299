import React from 'react';
import TwoStepAuth from '../components/TwoStepAuth';
import {Redirect} from 'react-router';
import AppBarTop from '../components/AppBarTop';
import {connect} from "react-redux";

class TwoStepAuthPage extends React.Component {
    render() {
        const {elkUser} = this.props;
        if (!elkUser.loggedIn()) {
            return <Redirect push to={`/login`}/>;
        }
        if (elkUser.loggedIn() && elkUser.passedTwoStepAuth()) {
            return <Redirect push to={`/main`}/>;
        }
        return (
            <React.Fragment>
                <AppBarTop/>
                <TwoStepAuth/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        elkUser: state.page.elkUser,
    };
};

export default connect(mapStateToProps)(TwoStepAuthPage);
