const initialState = {
    inited: false,
    currentReport: null,
    reports: [],
    dataGridRef: null
};

export function reports(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'REPORTS_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'REPORTS_SET_CURRENT_REPORT':
            return Object.assign({}, state, {
                currentReport: action.currentReport
            });
        case 'REPORTS_SET_REPORTS':
            return Object.assign({}, state, {
                reports: action.reports
            });
        case 'REPORTS_SET_DATAGRID_REF':
            return Object.assign({}, state, {
                dataGridRef: action.dataGridRef
            });
        default:
            return state;
    }
}
