export function ticketScannerSetInitialState() {
    return {
        type: 'TICKET_SCANNER_SET_INITIAL_STATE',
    };
}

export function ticketScannerResetScan() {
    return {
        type: 'TICKET_SCANNER_RESET_SCAN',
    };
}

export function ticketScannerSetData(data) {
    return {
        type: 'TICKET_SCANNER_SET_DATA',
        data
    };
}

export function ticketScannerSetIsValid(isValid) {
    return {
        type: 'TICKET_SCANNER_SET_IS_VALID',
        isValid
    };
}

export function ticketScannerSetIsLoading(isLoading) {
    return {
        type: 'TICKET_SCANNER_SET_IS_LOADING',
        isLoading
    };
}

export function ticketScannerSetError(error) {
    return {
        type: 'TICKET_SCANNER_SET_ERROR',
        error
    };
}

export function ticketScannerSetMessage(message) {
    return {
        type: 'TICKET_SCANNER_SET_MESSAGE',
        message
    };
}

export function ticketsScannerOpenResultDialog() {
    return {
        type: 'TICKET_SCANNER_OPEN_RESULT_DIALOG',
    };
}

export function ticketsScannerCloseResultDialog() {
    return {
        type: 'TICKET_SCANNER_CLOSE_RESULT_DIALOG',
    };
}

export function ticketScannerSetPlaceId(placeId) {
    return {
        type: 'TICKET_SCANNER_SET_PLACE_ID',
        placeId
    };
}

export function ticketScannerSetEventId(eventId) {
    return {
        type: 'TICKET_SCANNER_SET_EVENT_ID',
        eventId
    };
}

export function ticketScannerSetInitError(initError) {
    return {
        type: 'TICKET_SCANNER_SET_INIT_ERROR',
        initError
    };
}

export function ticketScannerSetInited(inited) {
    return {
        type: 'TICKET_SCANNER_SET_INITED',
        inited
    };
}

export function ticketScannerScan(scanData) {
    return async (dispatch, getState) => {
        const state = getState(), {placeId, eventId, resultDialog} = state.ticketScanner,
            client = state.page.elkUser.restClient;
        if (!scanData || resultDialog.open) {
            // dispatch(ticketScannerSetMessage('QR-код не распознан'));
            return;
        }
        try {
            dispatch(ticketScannerResetScan());
            dispatch(ticketScannerSetIsLoading(true));
            dispatch(ticketsScannerOpenResultDialog());
            let data = await client.post(`mkf_program/skd/check`, {
                code: scanData,
                place_id: placeId,
                event_id: eventId
            });
            dispatch(ticketScannerSetData(data));
        } catch (e) {
            console.error(e);
            dispatch(ticketScannerSetError(e));
        } finally {
            dispatch(ticketScannerSetIsLoading(false));
        }
    };
}

export function ticketScannerInit() {
    return async (dispatch, getState) => {
        try {
            // const state = getState(), client = state.page.elkUser.restClient;
            // let places = await client.get('mkf_program/skd/places');
            // dispatch(ticketScannerSetPlaces(places));
            dispatch(ticketScannerSetInitialState());
            dispatch(ticketScannerSetInited(true));
        } catch (e) {
            dispatch(ticketScannerSetInitError(true))
        }

    }
}
