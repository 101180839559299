import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import {ListItemText, Typography} from "@material-ui/core";
import {ticketScannerSetPlaceId, ticketScannerSetEventId} from "../../actions/ticketScanner";
import SimpleForm from "@ksbteam/core/components/Form/SimpleForm";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    }
}));

function PlaceOption(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="li"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                height: 'auto',
            }}
            {...props.innerProps}
        >
            <ListItemText disableTypography={true}>
                <Typography variant="body2">
                    {props.data.place}
                </Typography>
                <Typography variant="body2" color={'textSecondary'}>
                    {props.data.address}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
}

function PlaceSearch(props) {
    const classes = useStyles(), {placeId, setPlaceId, eventId, setEventId, restClient} = props;
    const onChange = (fieldName, fieldValue) => {
        if (fieldName === 'placeId') {
            setPlaceId(fieldValue.value);
            setEventId(null);
        }
        if (fieldName === 'eventId') {
            setEventId(fieldValue.value);
        }
    };
    return (
        <div className={classes.root}>
            <SimpleForm
                restClient={restClient}
                fields={[
                    {
                        label: "Выберите площадку",
                        attrs: {
                            name: "placeId",
                            type: "select"
                        },
                        searchable: true,
                        optionComponent: PlaceOption,
                        onChange: onChange,
                        optionsConfig: {
                            loadUrl: "/mkf_program/skd/places",
                        },
                    },
                    {
                        label: "Выберите мероприятие",
                        attrs: {
                            name: "eventId",
                            type: "select"
                        },
                        searchable: true,
                        onChange: onChange,
                        optionsConfig: {
                            loadUrl: "/mkf_program/skd/events",
                            loadUrlParams: {place_id: "placeId"}
                        },
                    }
                ]}
                values={{placeId, eventId}}
                hideSubmitButton={true}
                inited={true}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        placeId: state.ticketScanner.placeId,
        eventId: state.ticketScanner.eventId,
        restClient: state.page.elkUser.restClient
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPlaceId: (placeId) => dispatch(ticketScannerSetPlaceId(placeId)),
        setEventId: (eventId) => dispatch(ticketScannerSetEventId(eventId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceSearch);
