import React from 'react';
import Login from '../components/Login';
import {Redirect} from 'react-router';
import {connect} from "react-redux";

class LoginPage extends React.Component {
    render() {
        const {elkUser} = this.props;
        if (elkUser.loggedIn() && !elkUser.passedTwoStepAuth()) {
            return <Redirect push to={`/two-step-auth`}/>;
        }
        if (elkUser.loggedIn() && elkUser.passedTwoStepAuth()) {
            return <Redirect push to={`/main`}/>;
        }
        return <Login/>;
    }
}

const mapStateToProps = (state) => {
    return {
        elkUser: state.page.elkUser,
    };
};

export default connect(mapStateToProps)(LoginPage);

