import Cookies from 'universal-cookie';
import RestClient from '@ksbteam/core/api/RestClient';

const cookies = new Cookies();

export default class ElkUser {
    constructor() {
        this._authToken = cookies.get('authToken');
        this._twoStepAuth = cookies.get('twoStepAuth');
        this._reportsCount = cookies.get('reportsCount');
        this.initRestClient();
        this.initReports();
    }

    initRestClient() {
        if (!!this.authToken && !!this.authToken.length) {
            this.restClient = new RestClient({
                authToken: this.authToken,
                user: this,
                logoutUnauthorized: true,
                host: import.meta.env.VITE_REST_SERVICE
            });
        }
    }

    async initReports() {
        if (!!this.authToken && !!this.authToken.length && !!this.restClient) {
                let reports = await this.restClient.get('service_desc/report/list');
                this.reportsCount = reports.length;
        }
    }

    get reportsCount() {
        return this._reportsCount;
    }

    set reportsCount(value) {
        this._reportsCount = value;
        cookies.set('reportsCount', value, {path: '/'});
    }

    get authToken() {
        return this._authToken;
    }

    set authToken(value) {
        this._authToken = value;
        cookies.set('authToken', value, {path: '/'});
    }

    get twoStepAuth() {
        return this._twoStepAuth;
    }

    set twoStepAuth(value) {
        this._twoStepAuth = value;
        cookies.set('twoStepAuth', value, {path: '/'});
    }

    loggedIn() {
        return !!this.authToken && this.authToken.length;
    }

    passedTwoStepAuth() {
        return true;
        // return !!this.twoStepAuth && +this.twoStepAuth === 1;
    }

    async login(login, password) {
        const tempClient = new RestClient({
            authType: 'Basic',
            authToken: btoa(login + ':' + password),
            logoutUnauthorized: false,
            host: import.meta.env.VITE_REST_SERVICE
        });
        this.authToken = await tempClient.get('service_desc/login/token?fingerprint=123');
        this.initRestClient();
    }

    async passTwoStepAuth() {
        this.twoStepAuth = 1;
    }

    async logout() {
        this.authToken = '';
        this.twoStepAuth = 0;
        this.reportsCount = 0;
        this.goLoginPage();
    }

    goLoginPage() {
        if (!this.authToken.length && window.location.href.indexOf(`/login`) === -1) {
            window.location.href = `/login`;
        }
    }

    goMainPage() {
        if (this.authToken.length) {
            window.location.href = `/main`;
        }
    }

    goAuth2Step() {
        this.logout();
    }
}
