import React from 'react';
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {ticketsMapCreateTicket} from '../../actions/tickets';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(2),
        textTransform: 'none'
    }
}));

function CreateButton(props) {
    let {createCoords, mapCreateTicket} = props;
    const classes = useStyles();
    return (
        <Button
            className={classes.button}
            onClick={() => mapCreateTicket(createCoords[0], createCoords[1])}
            color={'secondary'}
            variant={'contained'}
        >
            <AddIcon/> {'Создать инцидент'}
        </Button>
    );
}

const mapStateToProps = (state) => {
    return {
        createCoords: state.tickets.map.createCoords
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        mapCreateTicket: (left, top) => dispatch(ticketsMapCreateTicket(left, top))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateButton);
