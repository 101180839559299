import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import QrReader from 'react-qr-reader';
import {ticketScannerInit, ticketScannerScan} from "../../actions/ticketScanner";
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaceSearch from './PlaceSearch';
import CommonMessage from '@ksbteam/core/components/CommonMessage';
import ResultDialog from './ResultDialog';

const useStyles = makeStyles(theme => ({
    progressWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    qrReader: {
        width: '100%'
    }
}));

function TicketScanner(props) {
    const {inited, initError, placeId, eventId, scan, init} = props;
    const classes = useStyles();
    const handleError = err => {
        console.error(err);
    };
    useEffect(() => {
        init();
    }, [init]);
    if (!inited) {
        return <div className={classes.progressWrapper}><CircularProgress/></div>;
    }
    if (!!initError) {
        return <CommonMessage messageCode={'initError'}/>;
    }
    return (
        <React.Fragment>
            <PlaceSearch/>
            {!!placeId && !!eventId
            && <QrReader delay={300} onError={handleError} onScan={scan} className={classes.qrReader}/>}
            <ResultDialog/>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        inited: state.ticketScanner.inited,
        initError: state.ticketScanner.initError,
        placeId: state.ticketScanner.placeId,
        eventId: state.ticketScanner.eventId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        scan: (data) => dispatch(ticketScannerScan(data)),
        init: () => dispatch(ticketScannerInit())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketScanner);
