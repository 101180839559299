import {combineReducers} from 'redux';
import {tickets} from "./tickets";
import {ticket} from "./ticket";
import {page} from "./page";
import {reports} from "./reports";
import {ticketScanner} from "./ticketScanner";
import { profile } from './profile';

export default combineReducers({
    tickets,
    ticket,
    page,
    reports,
    ticketScanner,
    profile
});
