import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import SimpleForm from '@ksbteam/core/components/Form/SimpleForm';
import {ticketAddComment} from "../../../actions/ticket";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    formWrapper: {
        marginBottom: theme.spacing(4)
    }
});

class CommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = () => {
        let values = this.simpleForm.getPreparedValues();
        if (!!values.comment && !!values.comment.length) {
            this.props.addComment(values.comment);
            this.simpleForm.setFieldValue('comment', '', true);
        }
    };

    render() {
        let {classes} = this.props;
        return (
            <React.Fragment>
                <div className={classes.formWrapper}>
                    <SimpleForm
                        ref={(simpleForm) => {
                            this.simpleForm = simpleForm;
                        }}
                        fields={[
                            {
                                label: "Текст комментария",
                                attrs: {
                                    "name": "comment",
                                    "type": "textarea"
                                }
                            }
                        ]}
                        hideSubmitButton={true}
                        inited={true}
                    />
                    <Button variant="contained" color="secondary" onClick={this.handleSubmit}>
                        <AddIcon/> {'Добавить'}
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        addComment: (comment) => dispatch(ticketAddComment(comment))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommentForm));
