import React from 'react';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        position: 'relative'
    },
    beenHereIcon: {
        "top": "0",
        "left": "0",
        "color": "#f33",
        "zIndex": "20",
        "position": "absolute",
        "fontSize": "46px"
    },
    addIcon: {
        "top": "4px",
        "left": "8px",
        "zIndex": "30",
        "position": "absolute",
        "color": "#ffffff",
        "background": "#f33",
        "borderRadius": "100%",
        "fontSize": "30px"
    }
});

export default function MapAddIcon() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <BeenhereIcon className={classes.beenHereIcon}/>
            <AddIcon className={classes.addIcon}/>
        </div>
    )
}
