import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import {
    ticketSetFormRef,
    ticketSave,
    ticketUpdateStatus
} from "../../actions/ticket";
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
    ticketButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    changeStatus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    changeStatusSelect: {
        width: 'calc(100% - 150px)'
    },
    editButtons: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        marginRight: theme.spacing(3)
    }
});

class TicketButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusValue: ''
        };
    }

    setStatus = (e) => {
        this.setState({statusValue: +e.target.value});
    };

    render() {
        const {classes, editMode, statuses, editDisabled, save, updateStatus} = this.props;
        const {statusValue} = this.state;
        return (
            <div className={classes.ticketButtons}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} className={classes.editButtons}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={save}
                            disabled={editDisabled}
                        >
                            <SaveIcon/> {'Сохранить'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div></div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {!editMode &&
                        <div className={classes.changeStatus}>
                            <Button variant="outlined" color="secondary" onClick={() => {
                                if (!!statusValue) {
                                    let title = statuses.find(status => statusValue === status.value).title;
                                    updateStatus({value: statusValue, title});
                                } else {
                                    alert('Выберите статус');
                                }
                            }}>
                                {'Перевести в:'}
                            </Button>
                            <Select
                                className={classes.changeStatusSelect}
                                native
                                value={statusValue}
                                onChange={this.setStatus}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}
                            >
                                <option value="">&nbsp;</option>
                                {statuses.map(status =>
                                    <option key={status.value} value={status.value}>{status.title}</option>
                                )}
                            </Select>
                        </div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        editDisabled: state.ticket.editDisabled,
        statuses: state.tickets.statuses,
        status: state.ticket.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFormRef: () => dispatch(ticketSetFormRef()),
        save: () => dispatch(ticketSave()),
        updateStatus: (status) => dispatch(ticketUpdateStatus(status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TicketButtons));
