import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import DefaultDialog from '@ksbteam/core/components/Dialog/DefaultDialog';
import {ticketsScannerCloseResultDialog} from "../../actions/ticketScanner";
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarError from '@ksbteam/core/components/Snackbar/SnackbarError';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    okButton: {
        margin: '24px auto',
        display: 'block'
    }
}));

function ResultDialog(props) {
    const classes = useStyles();
    const {data, isLoading, error, resultDialog, closeResultDialog} = props;
    return (
        <React.Fragment>
            <DefaultDialog
                {...resultDialog}
                fullScreen={true}
                title={'Информация о билете'}
            >
                {isLoading && <CircularProgress size={40}/>}
                {!isLoading &&
                <React.Fragment>
                    {!!error.length && <SnackbarError message={error}/>}
                    <Button
                        className={classes.okButton}
                        variant={'contained'}
                        color={'primary'}
                        onClick={closeResultDialog}>
                        ОК
                    </Button>
                    {!!data &&
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell><strong>ФИО участника</strong></TableCell>
                                <TableCell>
                                    {[data['FN_RUS'], data['LN_RUS']].join(' ')}<br/>
                                    {[data['FN_ENG'], data['LN_ENG']].join(' ')}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Мероприятие</strong></TableCell>
                                <TableCell>{data['EVENT_TITLE']}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Место проведения</strong></TableCell>
                                <TableCell>{data['PLACE_TITLE']}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Адрес</strong></TableCell>
                                <TableCell>{data['PLACE_ADDRESS']}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    }
                </React.Fragment>
                }
            </DefaultDialog>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        data: state.ticketScanner.data,
        isValid: state.ticketScanner.isValid,
        isLoading: state.ticketScanner.isLoading,
        error: state.ticketScanner.error,
        message: state.ticketScanner.message,
        resultDialog: state.ticketScanner.resultDialog,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeResultDialog: () => dispatch(ticketsScannerCloseResultDialog()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultDialog);
