import React from 'react';
import {Provider} from 'react-redux';
import configureStore from '../store/configureStore';
import Page from './Page';

const store = configureStore();

export default class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Page/>
            </Provider>
        );
    }
}
