export function profileSetInited(inited) {
    return {
        type: 'PROFILE_SET_INITED',
        inited
    };
}

export function setProfileData(profileData) {
    return {
        type: 'SET_PROFILE_DATA',
        profileData
    };
}

export function profileInit() {
    return async (dispatch, getState) => {
        const state = getState();
        const client = state.page.elkUser.restClient;
        dispatch(profileSetInited(false));
        const profileData = await client.get('service_desc/members/info');
        dispatch(setProfileData(profileData));
        dispatch(profileSetInited(true));
    };
}