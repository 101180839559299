import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {pageToggleDrawerLeft} from "../../actions/page";
import {mainMenu} from '../../api/MainMenu';

const drawerWidth = 280;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        border: 'none',
        backgroundColor: theme.palette.background.default
    },
    toolbar: theme.mixins.toolbar,
    navLink: {
        display: 'block',
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        // borderRadius: '0 50px 50px 0',
        '&.active': {
            background: '#e7e7e7',
            // color: theme.palette.secondary.main,
        },
        '&.active *': {
            // color: '#1967d2',
        }
    },
    listItem: {
        // borderRadius: '0 50px 50px 0',
    }
});

class DrawerLeft extends React.Component {
    render() {
        const {classes} = this.props;
        const menuItems = mainMenu.getHeaderItems();
        return (
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                {...this.props.drawerLeft}
                onClose={this.props.toggleDrawerLeft}
            >
                <List>
                    {menuItems.map(menuItem =>
                        <NavLink
                            key={menuItem.url}
                            to={menuItem.url}
                            className={classes.navLink}
                            onClick={this.props.toggleDrawerLeft}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemText primary={menuItem.title}/>
                            </ListItem>
                        </NavLink>
                    )}
                </List>
                {/*<Divider/>*/}
            </Drawer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        drawerLeft: state.page.drawerLeft,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDrawerLeft: () => dispatch(pageToggleDrawerLeft())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerLeft));
