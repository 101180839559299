export const DATAGRID_DEFAULT_PROPS = {
    disableInterfaceStorage: true,
    toolbarProps: {
        settingsEnabled: false
    },
    columns: [
        {
            "key": "mobile_card",
            "name": "Инциденты",
            "sortable": false,
            "width": document.documentElement.clientWidth - 46
        },
        {
            "key": "service_desc-created",
            "type": "datetime"
        },
        {
            "key": "service_desc-obj_id",
        },
        {
            "key": "service_desc-subject",
        },
        {
            "key": "service_desc-incident_type",
        },
        {
            "key": "service_desc-incident_level",
        },
        {
            "key": "service_desc-location",
        },
        {
            "key": "service_desc-description",
        },
        {
            "key": "service_desc-status",
        },
        {
            "key": "service_desc-is_danger",
        },
        {
            "key": "service_desc-assigned",
        },
        {
            "key": "service_desc-status_code",
            "visible": false
        }
    ]
};

export const TICKET_COLORS = {
    new: 'rgb(226, 100, 100)',
    completed: 'rgb(147, 220, 63)',
    assigned: 'rgb(83, 157, 232)'
};
