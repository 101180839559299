import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(3)
    },
    table: {
    },
});

class History extends React.Component {
    render() {
        let {classes, history} = this.props;
        return (
            <Paper className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата изменения</TableCell>
                            <TableCell>Пользователь</TableCell>
                            <TableCell>Действие</TableCell>
                            <TableCell>Поле</TableCell>
                            <TableCell>Старое значение</TableCell>
                            <TableCell>Новое значение</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map(item => (
                            <TableRow key={item.date}>
                                <TableCell>
                                    {!!item.date.length ? moment(item.date).format('DD.MM.YYYY HH:mm') : ''}
                                </TableCell>
                                <TableCell>{item.author}</TableCell>
                                <TableCell>{item.action}</TableCell>
                                <TableCell>{item.key}</TableCell>
                                <TableCell>{item.old}</TableCell>
                                <TableCell>{item.new}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        history: state.ticket.history
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(History));
