import React from 'react';
import { makeStyles } from '@material-ui/styles';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';

const useStyles = makeStyles({
    root: {
        position: 'relative'
    },
    whereToVoteIcon: {
        "top": "-67px",
        "left": "-23px",
        "color": "#f33",
        "zIndex": "40",
        "position": "absolute",
        "fontSize": "60px"
    },
    wrapper: {
        "top": "-60px",
        "left": "-8px",
        "color": "#ffffff",
        "width": "30px",
        "height": "30px",
        "zIndex": "30",
        "position": "absolute",
        "fontSize": "30px",
        "background": "#fff",
        "borderRadius": "100%"
    }

});

export default function MapHighLightIcon() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <WhereToVoteIcon className={classes.whereToVoteIcon}/>
            <div className={classes.wrapper}>&nbsp;</div>
        </div>
    )
}
