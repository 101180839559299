import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme, withStyles} from '@material-ui/core/styles';
import '../elk.css';
import {mainMenu} from '../api/MainMenu';
import {connect} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
    progressWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 300
    }
});

const theme = createMuiTheme({
    typography: {
        h1: {
            fontSize: 32, //37 - в макете
            lineHeight: 1.15
        },
        h2: {
            fontSize: 25,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 16, //18 - в макете
        },
    },
    palette: {
        secondary: {
            light: '#ab0d28',
            main: '#a50e2d',
        },
        text: {primary: '#5a5a5a'},
        background: {
            default: '#ffffff'
        }
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            'label': {
                fontSize: '18px !important',
            },
          },
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 18
                }
            }
        },
    }
});


class Page extends React.Component {
    render() {
        const {classes, elkUser, isMobileDevice} = this.props;
        if (!elkUser || isMobileDevice === null) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress size={40}/>
                </div>
            );
        }
        let routerItems = mainMenu.getRouterItems();
        return (
            <Router>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>
                    {routerItems.map(item =>
                        <Route key={item.url} path={item.url} component={item.component}/>
                    )}
                </MuiThemeProvider>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        elkUser: state.page.elkUser,
        isMobileDevice: state.page.isMobileDevice
    };
};

export default connect(mapStateToProps)(withStyles(styles)(Page));
