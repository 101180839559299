import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import EmailSelectForm from './EmailSelectForm';
import SendCodeForm from './SendCodeForm';
import SnackbarError from '@ksbteam/core/components/Snackbar/SnackbarError';
import CommonMessage from '@ksbteam/core/components/CommonMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {Redirect} from 'react-router';
import {connect} from "react-redux";

const styles = theme => ({
    rootPaper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: 650,
        maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
        margin: '100px auto 0',
        [`@media (max-width: 650px)`]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        }
    },
    progressWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
});

class TwoStepAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authError: '',
            emailValue: '',
            emailTitle: '',
            isLoading: false,
            hasLoadError: false,
            emailOptions: [],
            codeValidated: false,
            redirect: ''
        };
        this.handleSelectEmail = this.handleSelectEmail.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
        this.handleValidateCode = this.handleValidateCode.bind(this);
        this.handlePassAuth = this.handlePassAuth.bind(this);
    }

    async init() {
        this.setState({isLoading: true});
        try {
            let emailOptions = [], client = this.props.elkUser.restClient
            let emails = await client.get('icrobjects/login/get-email');
            for (let emailKey in emails) {
                emailOptions.push({value: emailKey, title: emails[emailKey]});
            }
            this.setState({emailOptions, isLoading: false});
        } catch (error) {
            console.error(error);
            this.setState({hasLoadError: true, isLoading: false});
        }
    }

    componentDidMount() {
        this.init();
    }

    handleSelectEmail = async (emailValue) => {
        let emailTitle = '', client = this.props.elkUser.restClient;
        this.state.emailOptions.forEach(emailOption => {
            if (emailOption.value === emailValue) {
                emailTitle = emailOption.title;
            }
        });
        await client.get(`icrobjects/login/set-email?id=${emailValue}`);
        await this.handleSendCode();
        this.setState({emailValue, emailTitle});
    };

    handleSendCode = () => {
        return this.client.get('icrobjects/access-code/create');
    };

    handleValidateCode = (code) => {
        return this.client.fetch(`icrobjects/access-code/validate?code=${code}`);
    };

    handlePassAuth = () => {
        this.props.elkUser.passTwoStepAuth();
        this.setState({redirect: `/main`});
    };

    render() {
        const {classes} = this.props;
        if (!!this.state.redirect) {
            return <Redirect push to={this.state.redirect}/>;
        }
        if (this.state.isLoading) {
            return (
                <Paper className={classes.rootPaper}>
                    <div className={classes.progressWrapper}><CircularProgress/></div>
                </Paper>
            );
        }
        if (this.state.hasLoadError) {
            return (
                <Paper className={classes.rootPaper}>
                    <CommonMessage messageCode={'initError'}/>
                </Paper>
            );
        }
        if (!this.state.isLoading && !this.state.emailOptions.length) {
            return (
                <Paper className={classes.rootPaper}>
                    <SnackbarError message={'Для Вашей учетной записи не указан ни один Email. ' +
                    'Пожалуйста, обратитесь к администратору сайта'}/>
                </Paper>
            );
        }
        return (
            <Paper className={classes.rootPaper}>
                {!this.state.emailValue && !!this.state.emailOptions.length &&
                <EmailSelectForm
                    emailValue={this.state.emailValue}
                    emailOptions={this.state.emailOptions}
                    onSubmit={this.handleSelectEmail}
                />
                }
                {!!this.state.emailValue &&
                <SendCodeForm
                    email={this.state.emailTitle}
                    onValidate={this.handleValidateCode}
                    onSendCode={this.handleSendCode}
                    onSubmit={this.handlePassAuth}
                />
                }

            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        elkUser: state.page.elkUser,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(TwoStepAuth));
