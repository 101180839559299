import React from 'react';
import ElkPage from '../containers/ElkPage';
import LoginPage from '../containers/LoginPage';
import TwoStepAuthPage from '../containers/TwoStepAuthPage';
import TicketScanner from '../components/TicketScanner';
import Tickets from '../components/Tickets';
import Reports from '../components/Reports';
import {Redirect} from "react-router";
import Map from '../components/Tickets/Map';
import ElkUser from "./ElkUser";
import Profile from '../components/Profile';

const TicketScannerPage = () => <ElkPage><TicketScanner/></ElkPage>;
const TicketsPage = () => <ElkPage><Tickets/></ElkPage>;
const ReportsPage = () => <ElkPage><Reports/></ElkPage>;
const ProfilePage = () => <ElkPage><Profile/></ElkPage>;

class MainMenu {
    constructor() {
        this.items = [
            {
                url: `/`,
                component: () => {
                    let pathnameArray = window.location.pathname.split('/');
                    if (!pathnameArray[1].length) {
                        return <Redirect push to={`/main`}/>;
                    }
                    return '';
                }
            },
            {
                url: `/login`,
                component: LoginPage,
            },
            {
                url: '/map',
                component: () => <div><Map/></div>
            },
            {
                url: `/two-step-auth`,
                component: TwoStepAuthPage,
            },
            {
                title: 'Проверка билетов',
                url: `/skd`,
                component: TicketScannerPage,
                // areas: [`header`]
            },
            {
                title: 'Инциденты',
                url: `/main`,
                component: TicketsPage,
                areas: [`header`]
            },
            {
                title: 'Отчеты',
                url: `/reports`,
                component: ReportsPage,
                areas: [`header`]
            },
            {
                title: 'Профиль',
                url: `/profile`,
                component: ProfilePage,
                areas: [`header`]
            },
        ];
    }

    getRouterItems() {
        let routerItems = [];
        this.items.forEach(menuItem => {
            let item = Object.assign({}, menuItem), items = [];
            if (!!item.subMenu) {
                items = items.concat(item, item.subMenu.items);
            } else {
                items.push(item);
            }
            routerItems = routerItems.concat(items);
        });
        return routerItems;
    }

    getHeaderItems() {
        const user = new ElkUser();
        let menuItems = this.items.filter(item => !!item.areas && item.areas.indexOf('header') !== -1);

        if (Number(user.reportsCount) === 0) {
            return menuItems.filter(item => item.url !== '/reports');
        } else {
            return menuItems;
        }
    }

    getHeaderIconsItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('header-icons') !== -1);
    }

    getProfileItems() {
        return this.items.filter(item => !!item.areas && item.areas.indexOf('profile') !== -1);
    }
}

export const mainMenu = new MainMenu();
