const scanInitialState = {
    data: null,
    isValid: null,
    isLoading: false,
    error: '',
    message: 'Нет данных',
    resultDialog: {
        open: false
    },
};
const initialState = {
    inited: false,
    initError: false,
    placeId: null,
    eventId: null,
    ...scanInitialState
};

export function ticketScanner(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'TICKET_SCANNER_SET_INITIAL_STATE': {
            return Object.assign({}, initialState);
        }
        case 'TICKET_SCANNER_RESET_SCAN': {
            return Object.assign({}, state, scanInitialState);
        }
        case 'TICKET_SCANNER_SET_INITED': {
            return Object.assign({}, state, {
                inited: action.inited
            });
        }
        case 'TICKET_SCANNER_SET_INIT_ERROR': {
            return Object.assign({}, state, {
                initError: action.initError
            });
        }
        case 'TICKET_SCANNER_SET_IS_VALID': {
            return Object.assign({}, state, {
                isValid: action.isValid
            });
        }
        case 'TICKET_SCANNER_SET_IS_LOADING': {
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        }
        case 'TICKET_SCANNER_SET_ERROR': {
            return Object.assign({}, state, {
                error: action.error
            });
        }
        case 'TICKET_SCANNER_OPEN_RESULT_DIALOG': {
            return Object.assign({}, state, {
                resultDialog: Object.assign({}, state.resultDialog, {open: true})
            });
        }
        case 'TICKET_SCANNER_CLOSE_RESULT_DIALOG': {
            return Object.assign({}, state, {
                resultDialog: Object.assign({}, state.resultDialog, {open: false})
            });
        }
        case 'TICKET_SCANNER_SET_MESSAGE': {
            return Object.assign({}, state, {
                message: action.message
            });
        }
        case 'TICKET_SCANNER_SET_DATA': {
            return Object.assign({}, state, {
                data: action.data
            });
        }
        case 'TICKET_SCANNER_SET_PLACE_ID': {
            return Object.assign({}, state, {
                placeId: action.placeId
            });
        }
        case 'TICKET_SCANNER_SET_EVENT_ID': {
            return Object.assign({}, state, {
                eventId: action.eventId
            });
        }
        default:
            return state;
    }
}
