import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Select from '@ksbteam/core/components/Form/Field/Select';
import SnackbarError from '@ksbteam/core/components/Snackbar/SnackbarError';
import Typography from '@material-ui/core/Typography';
import {t} from '@ksbteam/core/api/DummyTranslate';

const styles = theme => ({
    snackbarWrapper: {
        marginBottom: theme.spacing(2)
    },
    fieldError: {
        color: 'red',
        marginTop: theme.spacing(),
    },
    mainText: {
        marginBottom: theme.spacing()
    },
});

class EmailSelectForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectEmailError: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(values, actions) {
        actions.setSubmitting(true);
        try {
            this.setState({selectEmailError: ''});
            await this.props.onSubmit(values.email);
        } catch (error) {
            this.setState({
                selectEmailError: t('An error occurred while logging in. Please contact site administrator or try again later.')
            })
        }
        actions.setSubmitting(false);
    }

    render() {
        const {classes} = this.props;
        if (!!this.state.selectEmailError) {
            return <div className={classes.snackbarWrapper}>
                <SnackbarError message={this.state.selectEmailError}/>
            </div>
        }
        return (
            <Formik
                initialValues={{email: this.props.emailValue || ''}}
                onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .required(t('Field is required')),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;
                    return (
                        <form className={'simple-form'} onSubmit={handleSubmit}>
                            <Typography variant="body1" className={classes.mainText}>
                                {t('A one-time confirmation code will be sent to the selected Email')}
                            </Typography>
                            <div className="simple-form-row" style={{marginBottom: '1em'}}>
                                <Select
                                    id={'email'}
                                    label={false}
                                    options={this.props.emailOptions}
                                    onChange={handleChange}
                                    value={values.email}
                                    displayEmpty={true}
                                    onBlur={handleBlur}
                                    emptyOptionTitle={t('Select an Email')}
                                />
                                {errors.email && touched.email &&
                                <Typography variant="caption" gutterBottom className={classes.fieldError}>
                                    {errors.email}
                                </Typography>}
                            </div>

                            <Button
                                disabled={isSubmitting || !!Object.keys(errors).length}
                                type={'submit'}
                                variant="contained"
                                size="medium"
                                color="primary" style={{width: '100%', marginTop: '2em'}}
                            >
                                {t('Continue')}
                            </Button>
                        </form>
                    );
                }}
            </Formik>
        )
    }
}

export default withStyles(styles)(EmailSelectForm);

